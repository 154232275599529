import React from "react"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import GoogleImage from "../../components/GoogleImage"
import PDFDownloadBox from "../../components/PDFDownloadBox"

const InstallationRwPage = () => {
	return (
		<SupportPage page="installation" title="Raceway Sign Installation Instructions">
			<PageHeader title="Raceway Sign Installation Instructions" />

            <PDFDownloadBox
				title="Download Installation Instructions"
                pdfUrl="documents/Raceway.Lit.Letters.Logos.Installation.pdf"
				imageUrl="SignTypes/Install-Guide-thumb.jpg"
            />

            <h3>Required Tools <small>Accompanying Logos Referenced within Instructions</small></h3>
            
			{false && <GoogleImage src="Documents/installation-rw-01.png" required lazy alt="Raceway Installation Instructions" className="center" style={{width:"50%"}} />}
            <ul>
                <li>Level</li>
                <li>Safety Glasses</li>
                <li>Drill</li>
                <li>Fasteners</li>
                <li>Tape Measure</li>
                <li>Helper</li>
            </ul>

            <h3>Step 1 <small>Initial Examination</small></h3>

            <p>Inspect the crate for any damages.</p>
            <div className="help-block">
                If any damages are present on the crate, please contact Signmonkey.com immediately prior to signing for the sign.
            </div>
            <p>Unpack the power connection.</p>
            
			<GoogleImage src="Documents/installation-rw-02.jpg" required lazy alt="Raceway Installation Instructions" className="full" />

            <h3>Step 2 <small>Wall Preparation</small></h3>

            <p>Measure from the end of the raceway to the power hole.</p>
            <div className="help-block">
                In most cases this is 10 inches for the end of the raceway and 4.5 inches down from the top of the flange.
            </div>

			<GoogleImage src="Documents/installation-rw-03.jpg" required lazy alt="Raceway Installation Instructions" className="full" />

            <p>Remove the boards from the top of the crate.</p>

			<GoogleImage src="Documents/installation-rw-04.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Measure the overall length of the raceway to determine its physical center.</p>

			<GoogleImage src="Documents/installation-rw-05.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Place a piece of easily removable tape onto the wall at the center location of the raceway. From this point measure over until the 
            edge of the raceway, where another piece of tape should be placed.</p>

			<GoogleImage src="Documents/installation-rw-06.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Using the measurement taken from the end of the raceway to the power hole, and the previously established edge, locate and mark the 
            location of the power hole.</p>

			<GoogleImage src="Documents/installation-rw-07.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Determine the location of the first mounting hole according to the length and size of your raceway.</p>
            <div className="help-block">
                Additional information on the location of this mounting hole and the spacing between additional mounting holes can be found in our 
                mounting suggestions document in addition to the installation video.
            </div>

			<GoogleImage src="Documents/installation-rw-08.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Pre-drill the power hole and the established first mountng hole.</p>

			<GoogleImage src="Documents/installation-rw-09.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <h3>Step 3 <small>Unpacking and Installation of Raceway(s)</small></h3>

            <p>Unscrew the raceway’s mountng lip from the crate.</p>

			<GoogleImage src="Documents/installation-rw-10.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Remove the raceway from the crate, ensuring that the sign is lifted by the raceway itself.</p>
            <div className="help-block">
                CAUTION: Do NOT lit the sign from the letters themselves. Our signs are built to a high quality, but the individual letters are 
                not designed to support the weight of the entire sign.
            </div>

			<GoogleImage src="Documents/installation-rw-11.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Slide the power wires through the pre-drilled power hole in the wall.</p>

			<GoogleImage src="Documents/installation-rw-12.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Begin mounting the raceway to the wall through the pre-drilled mounting hole.</p>
            <div className="help-block">
                Suggestions for fasteners can be found within the literature located on the website.
            </div>

			<GoogleImage src="Documents/installation-rw-13.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>With your helper holding the other end, make connections between any adjoining raceways and slide the ends together.</p>

			<GoogleImage src="Documents/installation-rw-14.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Mount each additional raceway to the wall after leveling to the raceway directly before it. Continue installing all the 
            mounting screws through the top and bottom flange.</p>

			<GoogleImage src="Documents/installation-rw-15.jpg" required lazy alt="Raceway Installation Instructions " className="full" />

            <p>Make the final connection to your power source</p>

            <p>Turn your sign on. (You’re Done!)</p>
		</SupportPage>
	)
}

export default InstallationRwPage